import React from "react";
import Layout from "../../components/Layout";
import JobRoll from "../../components/JobRoll";

function index() {
  return (
    <div>
      <Layout className='font-sans'>
        <div
          className='full-width-image-container margin-top-0'
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1
            className='has-text-weight-bold is-size-1'
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              padding: "1rem",
            }}
          >
            Openings at Bristol Place
          </h1>
        </div>
        <section className='section'>
          <div className='container'>
            <div className='content'>
              <JobRoll />
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
}

export default index;
